import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { CompanyContext } from "./CompanyContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

// days js support
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const CampaignContext = createContext();

export const CampaignContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);
    const { companyKey, companyID, companyHistoryDaySpan, allBusinessCampaigns, allCampaignKeysGrouped } = useContext(CompanyContext);

    const [loadingAllBusinessEvents, setLoadingAllBusinessEvents] = useState(false);
    const [allBusinessEvents, setAllBusinessEvents] = useState([]);
    const [eventDataDirty, setEventDataDirty] = useState(false);

    // toggle this to force re-reading and re-calculating info from DBs
    // (typically because an event changed in the database)
    const [refreshCampaignContext, setRefreshCampaignContext] = useState(false);

    const [campaignKey, setCampaignKey] = useState('');

    const [campaignID, setCampaignID] = useState(0);
    const [campaignName, setCampaignName] = useState('');

    const [campaignStats, setCampaignStats] = useState(0);

    //eslint-disable-next-line
    const [loadingCampaignStatsFromSQL, setLoadingCampaignStatsFromSQL] = useState(false);

    //eslint-disable-next-line
    const [loadingCampaignEvents, setLoadingCampaignEvents] = useState(false);

    const [campaignEvents, setCampaignEvents] = useState([]);
    const [campaignProjectCodeMsgCounts, setCampaignProjectCodeMsgCounts] = useState([]);

    const [responsesStart, setResponsesStart] = useState('');

    const [responseStats, setResponseStats] = useState([]);
    const [responseCategories, setResponseCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(false);

    const [campaignStateID, setCampaignStateID] = useState(0);
    const [campaignStateAbbr, setCampaignStateAbbr] = useState('');

    const [campaignApprovers, setCampaignApprovers] = useState([]);
    const [campaignSampleRecipients, setCampaignSampleRecipients] = useState([]);

    const [areaCodeList, setAreaCodeList] = useState([]);
    const [defaultTimeZone, setDefaultTimeZone] = useState('');

    //unit pricing information
    const [campaignPricingInformation, setCampaignPricingInformation] = useState();

    const [mongoCampaignDetails, setMongoCampaignDetails] = useState('');

    //************************************* TN SEND INFORMATION **********************************

    const [adminBWApplicationID, setAdminBWApplicationID] = useState('');
    const [custBWApplicationID, setCustBWApplicationID] = useState('');

    //************************************* TN SEND INFORMATION **********************************

    //************************************* BW Application Save Progress Info ********************
    const [updatedStamp, setUpdatedStamp] = useState(0);
    const [campaignSaving, setCampaignSaving] = useState(false);

    //type navigateToNewCampaign = {type: '', newCampaignID_legacy: 0, campaignName: '', FirstStartDate: ''};
    const [newCampaignNavData, setNewCampaignNavData] = useState([]);

    const [campaignSavingComplete, setCampaignSavingComplete] = useState(false);
    //********************************************************************************************

    //used to identify if the campaign was initially created via the legacy Amplify application rather than the SaaS portal
    const [legacyAmplify, setLegacyAmplify] = useState(false);


    useEffect(() => {
        // This just gets the list of campaign approvers
        // Not sure why it's defined here instead of outside
        // with getUniqueCampaignSampleRecipients()

        // This should be redefined outside as getUniqueCampaignApprovers()
        const collectData = async() => {
            if (jwt && campaignKey != '') {
                await axios.get(
                    `${config.accountapi.url}/api/v1/campaigns/${campaignKey}`,
                    { headers: { Authorization: "Bearer " + jwt } }
                )
                .catch(function (error) {
                    console.log(error);
                })
                .then(function (response) {
                    setCampaignApprovers(response.data["approval_numbers"]);
                });
            }
        }
        collectData();
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }, [campaignKey])

    useEffect(() => {
        if (eventDataDirty) {
            getAllBusinessEvents();
        }
    }, [refreshCampaignContext]);

    useEffect(() => {
        refreshCampaign();
    }, [campaignID]);

    useEffect(() => {
        getAllBusinessEvents();
    }, [allCampaignKeysGrouped]);

    useEffect(() => {
        createCampaignEventsList();
    }, [allBusinessEvents]);

    useEffect(() => {
        if (companyID) {
            getStateInfo(campaignStateID);
        }
    }, [campaignStateID])


    function newCampaignPricingInformation() {
        return {
            smsBasePrice: 0.04,
            mmsBasePrice: 0.065,
            additionalSegmentPrice: 0.0075,
            crystalBasePrice: 0.005,
            mmsIncludedCharacters: 160
        };
    }

    function resetCampaignVals(){
        setCampaignKey('');
        setCampaignName('');

        setCampaignStats(0);
        setCampaignEvents([]);
        setCampaignProjectCodeMsgCounts([]);

        setResponsesStart([]);
        setResponseCategories([]);
        setLoadingCategories(false);

        setCampaignStateID(0);
        setCampaignStateAbbr('');

        setCampaignApprovers([]);
        setCampaignSampleRecipients([]);

        setAreaCodeList([]);
        setCampaignPricingInformation(newCampaignPricingInformation());

        setMongoCampaignDetails('');
        setAdminBWApplicationID('');
        setCustBWApplicationID('');

        setLegacyAmplify(false);
    }

    // Called by outside elements to check if data is dirty and needs to be re-read
    function checkCampaignContextRefresh() {
        setRefreshCampaignContext(!refreshCampaignContext);
    }

    // edr - this should only be called when a particular campaign is being selected
    //       and it should only update vars that are specific campaign's details
    async function refreshCampaign() {
        if (!companyID || !campaignID) {
            return;
        }
        resetCampaignVals();
        createCampaignEventsList();
        getCampaignStatsFromSQL(campaignID);
        getCampaignProjectCodeCounts(companyID, campaignID);
        getCampaignResponseTypeCounts(companyID, campaignID);

        // this function was removed because it didn't do anything with results of API call
        // probably should write a new proper function
        //getUniqueCampaignApprovers(companyID, campaignID);

        getUniqueCampaignSampleRecipients(companyID, campaignID);
    }

    async function updateCampaignID(CampaignID){
        setCampaignID(CampaignID);
        getCampaignStatsFromSQL(CampaignID);
    }

    // Get stats data from API
    const getCampaignStatsFromSQL = async (campaignID) => {
    //async function getCampaignStatsFromSQL(campaignID){
        if (!jwt || !campaignID) {
            return;
        }

        setLoadingCampaignStatsFromSQL(true);
        var pricing = newCampaignPricingInformation();

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectAmplifyKeyStatsPerCampaign_2?dayspan=${companyHistoryDaySpan}&campaign_id=${campaignID}&company_id=${companyID}`,
            { headers: { Authorization: "Bearer " + jwt } }
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            if (response.data.values.length > 0) {
                setCampaignStateID(response.data.values[0]['stateID']);
                setCampaignStateAbbr(response.data.values[0]['stateAbbr']);
                setCampaignName(response.data.values[0]['campaignName'])
                setLegacyAmplify(response.data.values[0]['legacyAmplify'])

                //set base pricing information
                pricing['smsBasePrice'] = response.data.values[0]['smsSegmentPrice'];
                pricing['mmsBasePrice'] = response.data.values[0]['mmsSegmentPrice'];
                pricing['additionalSegmentPrice'] = response.data.values[0]['additionalSegmentPrice'];
                pricing['crystalBasePrice'] = response.data.values[0]['CrystalBasePrice'];
                pricing['mmsIncludedCharacters'] = response.data.values[0]['MMSIncludedCharacters'];
                setCampaignPricingInformation(pricing);
            }
        });

        await axios.get(
            `${config.accountapi.url}/api/v1/campaigns?campaign_id_legacy=${campaignID}`,
            { headers: { Authorization: "Bearer " + jwt } }
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            if(response.data.values.length > 0){
                setDefaultTimeZone(response.data.values[0]['timezone']);
                setCampaignKey(response.data.values[0]['key']);
            }
            else{
                console.log("***** ERROR ***** unable to establish default Timezone");
            }
        });

        setLoadingCampaignStatsFromSQL(false);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt

    }

    // Get all events for this company (which requires a list of all the company's campaigns)
    const getAllBusinessEvents = async () => {
        if (!jwt || !companyID || companyID < 0 ||
            !companyHistoryDaySpan || !allCampaignKeysGrouped)
        {
            return;
        }

        setLoadingAllBusinessEvents(true);

        var _renderType = "desktop";
        var maxLen = 30;

        if (window.innerWidth >= 1600) {
            _renderType = "desktop";
        } else if (window.innerWidth < 1600 && window.innerWidth > 450) {
            _renderType = "tablet";
        } else {
            _renderType ="phone";
        }

        var _final_events = [];
        var _args_for_summary =
            [
                "time_window=" + companyHistoryDaySpan,
                "company_id=" + companyID,
            ];
        var _arg_string_summary = _args_for_summary.join("&");
        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectexecutivesummary?${_arg_string_summary}`,
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            response.data.values.forEach(object => {
                var _dtCalc_M = getEventStartDateTime(object['EventStart'] + "T" + object['MSGWindow_Start'],
                    object['TZ_Abbrev']);

                var _eventName = object['Event Name'];
                if(_eventName.substring(0,8) == _dtCalc_M.dispRequestedDate + " - "){
                    _eventName = "(" + _dtCalc_M.dispRequestedDate + ") " + _eventName.substring(8);
                }

                _final_events.push(
                    [
                        object['Campaign Name'],
                        object['ProjectCode'],
                        _eventName,
                        object['totTargetsFormatted'],
                        _dtCalc_M.dispRequestedTime,
                        _dtCalc_M.timeZoneAbbrev,
                        object['Event Status'].toUpperCase(),
                        _dtCalc_M.utcDateTime.format("YYYY/MM/DD HH:mm"),    //UTC Time for sorting
                        object['CorrelationID'],
                        object['EventStart'],
                        object['Approved'],
                        object['ID'],                             //legacy campaignid
                        object['Mongo_EventKey'],
                        object['LegacyAmplify']
                    ]
                );
            });
        }); // end of response

        // loop over campaign groups getting all the events
        var _startDateSpan = dayjs().subtract(companyHistoryDaySpan, 'day').format("YYYY-MM-DDT00:00");
        const _arg_list_events =
            [
                "limit=1000",
                "status=process", "status=processing", "status=processed",
                "status=edit", "status=editing", "status=edited",
                "status=approve", "status=approving", "status=approved",
                "status=commit", "status=committing", "status=committed",
                "status=reviewing",
                "status=released",
                "status=sending",
                "status=completed",
                "status=created",
            ];
        const _arg_string_events = _arg_list_events.join("&");

        var _mongo_events = [];
        var _mongo_events_raw = [];

        // use campaign key groups to reduce the number of queries
        for (let _index=0; _index < allCampaignKeysGrouped.length; _index++) {
            var _group_arg_string = "campaign_key=" + allCampaignKeysGrouped[_index].join("&campaign_key=");
            await axios.get(
                `${config.eventapi.url}/api/v1/events?start_datetime={"$gt":"${_startDateSpan}"}&${_group_arg_string}&${_arg_string_events}`,
                {headers: { Authorization: "Bearer " + jwt }}
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                response.data.values.forEach(object => {
                    // only grab events we don't already have
                    if (!(_final_events.find(m => m[12] == object['key']))) {

                        // only grab events within desired time frame
                        // edr - I think this is already handled by the query args
                        var _datetime_frame = getEventStartDateTime(object['start_datetime'], object['timezone'], true);
                        if (_datetime_frame.withinTimeWindow == true) {
                            _mongo_events_raw.push(object);
                        } else {
                            console.log("EDR - outside of time window: ", object);
                        }
                    }
                });
            });
        } // end of groups loop

        // look up all the voterset row counts
        for (let i=0; i < _mongo_events_raw.length; i++) {
            _mongo_events_raw[i]['vs_row_count'] = "(undefined)";
            if (!_mongo_events_raw[i]['voterset_key']) {
                continue;
            }

            await axios.get(
                `${config.voterapi.url}/api/v1/votersets/${_mongo_events_raw[i]['voterset_key']}`,
                { headers: { Authorization: "Bearer " + jwt } }
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                _mongo_events_raw[i]['vs_row_count'] = response.data.voter_map.metadata['total_rows'];
            });
        }

        // parse raw mongo data for elements we need
        _mongo_events_raw.forEach(object => {
            var _mongo_campaign = allBusinessCampaigns.find(campaign => campaign.key == object['campaign_key']);
            var _dtCalc = getEventStartDateTime(object['start_datetime'], object['timezone'], true);
            _mongo_events.push(
                [
                    _mongo_campaign.name,
                    object['project_code'],
                    "(" + _dtCalc.dispRequestedDate + ") " +  object['name'],
                    object['vs_row_count'].toLocaleString('en-US'),
                    _dtCalc.dispRequestedTime,
                    _dtCalc.timeZoneAbbrev,
                    object['status'].toUpperCase(),
                    _dtCalc.utcDateTime.format("YYYY/MM/DD HH:mm"),    //UTC Time for sorting
                    object['key'],
                    object['start_datetime'],
                    object['status'],                     //TODO: Need to identify approval code storage
                    _mongo_campaign.legacy_id,
                    object['key']
                ]
            );
        });
        _final_events = [..._final_events, ..._mongo_events];

        // this maxLen for the 'name' field  is the only difference from the EventList component
        switch (_renderType) {
            case "desktop":
                maxLen = 24;
                break;
            case "tablet":
                maxLen = 24;
                break;
            case "phone":
                maxLen = 20;
                break;
        }

        // shorten some strings for readability
        _final_events.forEach(object => {
            // campaign name
            if (object[0].length > 13)
                object[0] = object[0].substring(0,12) + "...";

            // project code
            if (object[1].length > 10)
                object[1] = object[1].substring(0,9) + "...";

            // event name
            if (object[2].length > maxLen)
                object[2] = object[2].substring(0, maxLen-1) + "...";
        });

        setAllBusinessEvents(_final_events);
        setLoadingAllBusinessEvents(false);
        setEventDataDirty(false);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    // list of events for the selected campaign
    const createCampaignEventsList = async () => {
        if (!jwt || !campaignID) {
            return;
        }

        setLoadingCampaignEvents(true);

        var tempEventList = [];
        allBusinessEvents.forEach(object => {
            //if (object[12] == campaignKey) {
            if (object[11] == campaignID) {
                tempEventList.push([
                    object[1],    // project code
                    object[2],    // event name
                    object[3],    // count
                    object[7].split(' ')[0],    // start date
                    object[4],    // start time
                    object[5],    // timezone
                    object[6],    // status
                    object[8],    // legacy event id or the mongo key (depending upon status)
                    object[12],   // event key
                    object[7],    // utc timestamp
                ]);
            }
        });

        setCampaignEvents(tempEventList);
        setLoadingCampaignEvents(false);
    };

    //SUPPORT DATE ADJUSTMENTS

    function getTimezoneStruct(timezone) {
        var tz_struct;

        if (!timezone) {
            return tz_struct;
        }

        if (["Alaskan Standard Time", "AKT", "alaska"].includes(timezone)) {
            tz_struct = {
                tz: "America/Anchorage",
                tzAbbrev: 'AKT',
                offSet: -9
            }
        }
        else if (["Hawaiian Standard Time", "HIT", "hawaii"].includes(timezone)) {
            tz_struct = {
                tz: "Pacific/Honolulu",
                tzAbbrev: 'HIT',
                offSet: -10
            }
        }
        else if (["Pacific Standard Time", "PT", "pacific"].includes(timezone)) {
            tz_struct = {
                tz: "America/Los_Angeles",
                tzAbbrev: 'PT',
                offSet: -8
            }
        }
        else if (["Arizona Standard Time", "AZT", "arizona"].includes(timezone)) {
            tz_struct = {
                tz: "America/Phoenix",
                tzAbbrev: 'AZT',
                offSet: -8
            }
        }
        else if (["Mountain Standard Time", "MT", "mountain"].includes(timezone)) {
            tz_struct = {
                tz: "America/Denver",
                tzAbbrev: 'MT',
                offSet: -7
            }
        }
        else if (["Central Standard Time", "CT", "central"].includes(timezone)) {
            tz_struct = {
                tz: "America/Chicago",
                tzAbbrev: 'CT',
                offSet: -6
            }
        }
        else if (["Eastern Standard Time", "ET", "eastern"].includes(timezone)) {
            tz_struct = {
                tz: "America/New_York",
                tzAbbrev: 'ET',
                offSet: -5
            }
        }
        return tz_struct;
    }

    function getEventStartDateTime(startUTCDateTime, timezone, isMongo=false) {
        var _retVal = {
                utcDateTime: 0,
                origTimeZone: '',
                timeZone: '',
                timeZoneOffset: 0,
                timeZoneAbbrev: '',
                requestedDateTime: 0,
                dispRequestedDate: '',
                dispRequestedTime: '',
                withinTimeWindow: true
            };

        try {
            var _tz_struct = getTimezoneStruct(timezone);
            _retVal.origTimeZone = timezone;
            _retVal.timeZone = _tz_struct.tz;
            _retVal.timeZoneOffset = _tz_struct.offset;
            _retVal.timeZoneAbbrev = _tz_struct.tzAbbrev;
            _retVal.utcDateTime = dayjs.utc(startUTCDateTime);
            _retVal.requestedDateTime = _retVal.utcDateTime.tz(_tz_struct.tz);
            _retVal.dispRequestedDate = _retVal.requestedDateTime.format("MM/DD");
            _retVal.dispRequestedTime = _retVal.requestedDateTime.format("HH:mm");

            if (isMongo) {
                var _comparison = dayjs().subtract(companyHistoryDaySpan, 'day');
                var thisUTCDateTime = dayjs(_retVal.utcDateTime);
                // console.log("Date Comparison:     _comparison = ", _comparison);
                // console.log("Date Comparison: thisUTCDateTime = ", thisUTCDateTime);
                if (
                    // edr: not sure why compared like this, it doesn't work in new year
                    //(dayjs(_retVal.utcDateTime).dayOfYear() >= dayjs(_comparison).dayOfYear()) &&
                    //(dayjs(_retVal.utcDateTime).year() >= dayjs(_comparison).year())
                    thisUTCDateTime >= _comparison
                   )
                {
                    _retVal.withinTimeWindow = true;
                } else {
                    _retVal.withinTimeWindow = false;
                }
            }

        } catch(err) {
            console.log("EventList: getEventStartDateTime() - there was an error parsing the requested event date/time", err);
        }
        return _retVal;
    }

    //END SUPPORT OF DATE ADJUSTMENTS


    const getCampaignProjectCodeCounts = async (companyID, campaignID) => {
        var _counts = [];
        if (jwt && companyID && campaignID) {
            await axios.get(
                `${config.reportapi.url}/api/v1/event/collectCampaignTargetCountsByProjectCode?time_window=${companyHistoryDaySpan}&company_id=${companyID}&campaign_id=${campaignID}`,
                { headers: { Authorization: "Bearer " + jwt } }
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                _counts = response.data.values;
            });
        }
        setCampaignProjectCodeMsgCounts(_counts);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    const getCampaignResponseTypeCounts = async (companyID, campaignID) => {
        if (!jwt || !companyID) {
            return;
        }

        setLoadingCategories(true);

        await axios.get(
            `${config.reportapi.url}/api/v1/event/collectCampaignResponseTypeCounts?time_window=${companyHistoryDaySpan}&company_id=${companyID}&campaign_id=${campaignID}`,
            { headers: { Authorization: "Bearer " + jwt } }
        )
        .catch(function (error) {
            console.log(error);
        })
        .then(function (response) {
            if (response && response.data && response.data.values.length > 0) {
                var _optouts = [];
                var _responses = [];
                var _project_codes = [];

                // iterate over results to collect response types and counts
                response.data.values.forEach(object => {
                    if (_project_codes.includes(object["ProjectCode"]) == false)
                        _project_codes.push(object["ProjectCode"]);

                    switch (object["respType"]) {
                        case 'OptOut':
                            _optouts.push(object["msgCount"]);
                            break;
                        case 'Target Message':
                            _responses.push(object["msgCount"]);
                            break;
                    }
                });

                // resulting values
                var respO = {name: 'OptOut', data: _optouts};
                var respR = {name: 'Other Responses', data: _responses};
                setResponseStats([respO, respR]);
                setResponseCategories(_project_codes);
            }
        });

        //collect Mongo campaign details
        await axios.get(
            `${config.accountapi.url}/api/v1/campaigns?limit=5000&business_key=${companyKey}`,
            { headers: { Authorization: "Bearer " + jwt, accept: "application/json" } }
        )
        .catch(function (error) {
            console.log("There was an error collecting the campaign details -> ", error);
        })
        .then(function (response) {
            if (response.data.values) {
                var _found = response.data.values.find((e) => e.campaign_id_legacy == campaignID); //campaignID
                if(_found != null){
                    setAdminBWApplicationID(_found['admin_bw_application_id']);
                    setCustBWApplicationID(_found['customer_bw_application_id']);
                    setCampaignKey(_found['key']);
                }
            }
        });

        setLoadingCategories(false);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    const getUniqueCampaignSampleRecipients = async (companyID, campaignID) => {
        var _rec_list = [];
        if (jwt && companyID) {
            await axios.get(
                `${config.reportapi.url}/api/v1/event/collectHistoricalCampaignSampleRecipients?company_id=${companyID}&campaign_id=${campaignID}`,
                { headers: { Authorization: "Bearer " + jwt } }
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                _rec_list = response.data.values;
            });
        }
        setCampaignSampleRecipients(_rec_list);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    // get the area code list
    const getStateInfo = async (stateid) => {
        var ac_list = [];
        if (jwt && stateid) {
            await axios.get(
                `${config.reportapi.url}/api/v1/event/collectAllStateInformation?state_id=${stateid}`,
                { headers: { Authorization: "Bearer " + jwt } }
            )
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                response.data.values.forEach(object => {
                    ac_list.push(object['AreaCode']);
                });
            });
        }
        setAreaCodeList(ac_list);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    };

    async function checkCampaignForAppIDs(campaign_key) {
        // console.log("CampaignContext: entered checkCampaignForAppIDs() (NEEDS TESTING)");

        setCampaignSaving(true);
        setCampaignSavingComplete(false);

        //eslint-disable-next-line
        var i = 1;
        var _newUpdateStamp = updatedStamp;
        var t = setInterval(async function() {
            // the update has not been processed yet by the server, so keep checking
            if (_newUpdateStamp == updatedStamp) {
                await axios.get(
                    `${config.accountapi.url}/api/v1/campaigns/${campaign_key}`,
                    {headers: { Authorization: "Bearer " + jwt, accept: "application/json" }}
                )
                .catch(function (error) {
                    console.log("error collecting the updated tn information", error);
                    setCampaignSaving(false);
                })
                .then(function (response) {
                    if (response && response.data['admin_bw_application_id'] && response.data['customer_bw_application_id']) {
                        _newUpdateStamp = response.data['updated_dt'];
                        if(_newUpdateStamp != updatedStamp){
                            setCampaignSaving(false);
                            setCampaignSavingComplete(true);
                        }
                        else{
                            console.log("POST - no new TN collected:", _newUpdateStamp);
                        }
                    }
                });
            }
            else{
                setUpdatedStamp(_newUpdateStamp);
                clearInterval(t);
            }
            i++;
        }, 2500);

        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    // EDR - NEEDS TESTING
    async function attachNewApproverToCampaign(newlyCreatedApprover) {
        // console.log("CampaignContext: entered attachNewApproverToCampaign() (NEEDS TESTING)");

        if (!newlyCreatedApprover) {
            return;
        }

        var _found = false;
        if (campaignApprovers) {
            var _newApproverPhone = newlyCreatedApprover.phone;
            for (var i = 0; i < campaignApprovers.length; i++) {
                if (campaignApprovers[i].ApproverPhone == _newApproverPhone) {
                    _found = true;
                    break;
                }
            }
        }

        if (!_found) {
            var _newCampaignApprover =
                {
                    "ApproverName": newlyCreatedApprover.name,
                    "ApproverPhone": newlyCreatedApprover.phone,
                    "Selected": 0
                };

            campaignApprovers.splice(campaignApprovers.length, 0, _newCampaignApprover);
            // console.log("campaign key =>", campaignKey);
            // console.log("approvers =>", campaignApprovers);

            await axios.patch(
                `${config.accountapi.url}/api/v1/campaigns/${campaignKey}`,
                {"key": campaignKey, "approval_numbers": campaignApprovers},
                {headers: { Authorization: "Bearer " + jwt }}
            )
            .catch(function (error) {
                console.log("unable to store the campaign approvers listing to the campaign => ", error);
            });
            // .then(function (response) {
            // })
            dispatch({ type: "CHECK_AUTH" });  // refresh jwt
        }
    }

    // EDR - NEEDS TESTING
    async function removeApproverFromCampaign(ApproverToRemove) {
        // console.log("CampaignContext: entered removeApproverFromCampaign() (NEEDS TESTING)");

        var _newApproverList = [];
        var _found = false;
        var _phoneToRemove = ApproverToRemove.ApproverPhone;
        if (ApproverToRemove.ApproverPhone.length == 10) {
            _phoneToRemove = "+1" + ApproverToRemove.ApproverPhone;
        }

        campaignApprovers.forEach(object => {
            var _phoneToCheck = object["ApproverPhone"];
            if (_phoneToCheck.length == 10) {
                _phoneToCheck = "+1" + object["ApproverPhone"];
            }

            if (object["ApproverName"] == ApproverToRemove.ApproverName && _phoneToCheck == _phoneToRemove && _found == false) {
                //skip to remove
                _found = true;
            }
            else{
                _newApproverList.push({ApproverName: object["ApproverName"], ApproverPhone: object["ApproverPhone"], Selected: 0});
            }
        });

        await axios.patch(
            `${config.accountapi.url}/api/v1/campaigns/${campaignKey}`,
            {"key": campaignKey, "approval_numbers": _newApproverList},
            {headers: { Authorization: "Bearer " + jwt }}
        )
        .catch(function (error) {
            console.log("there was an error updating the campaign approvers to remove the selected approver => ", error);
        });
        // .then(function (response) {
        // })

        setCampaignApprovers(_newApproverList);
        dispatch({ type: "CHECK_AUTH" });  // refresh jwt
    }

    return (
        <CampaignContext.Provider value={{
            campaignID:                     campaignID,
            setCampaignID:                  setCampaignID,
            campaignEvents:                 campaignEvents,
            setCampaignName:                setCampaignName,
            campaignName:                   campaignName,
            companyID:                      companyID,
            responseStats:                  responseStats,
            responseCategories:             responseCategories,
            responsesStart:                 responsesStart,
            setResponsesStart:              setResponsesStart,
            campaignStats :                 campaignStats,
            setCampaignEvents:              setCampaignEvents,
            campaignProjectCodeMsgCounts :  campaignProjectCodeMsgCounts,
            campaignStateID:                campaignStateID,
            campaignStateAbbr:              campaignStateAbbr,
            campaignApprovers:              campaignApprovers,
            campaignSampleRecipients:       campaignSampleRecipients,
            areaCodeList:                   areaCodeList,
            campaignPricingInformation:     campaignPricingInformation,
            getCampaignStatsFromSQL:        getCampaignStatsFromSQL,
            mongoCampaignDetails:           mongoCampaignDetails,
            loadingCategories:              loadingCategories,
            campaignKey:                    campaignKey,
            setCampaignKey:                 setCampaignKey,
            updateCampaignID:               updateCampaignID,
            adminBWApplicationID:           adminBWApplicationID,
            custBWApplicationID:            custBWApplicationID,
            resetCampaignVals:              resetCampaignVals,
            campaignSaving:                 campaignSaving,
            checkCampaignForAppIDs:         checkCampaignForAppIDs,
            newCampaignNavData:             newCampaignNavData,
            setNewCampaignNavData:          setNewCampaignNavData,
            campaignSavingComplete:         campaignSavingComplete,
            defaultTimeZone:                defaultTimeZone,
            legacyAmplify:                  legacyAmplify,
            setLegacyAmplify:               setLegacyAmplify,
            refreshCampaign:                refreshCampaign,
            attachNewApproverToCampaign:    attachNewApproverToCampaign,
            removeApproverFromCampaign:     removeApproverFromCampaign,
            allBusinessEvents:              allBusinessEvents,
            loadingAllBusinessEvents:       loadingAllBusinessEvents,
            setEventDataDirty:              setEventDataDirty,
            checkCampaignContextRefresh:    checkCampaignContextRefresh,
        }}>
            {children}
        </CampaignContext.Provider>
    );
}
